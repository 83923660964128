import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { ethers } from "ethers";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import Link from "@mui/material/Link";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typist from "react-typist";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";

import "../../styles/App.css";

import { contractAddress, moralisNode, firebaseNode } from "../../config/config";
import contract from "../../contracts/TiiN_Rabbit_Collection.json";
import {
  setMessage,
  setType,
  setOpen,
  setLink,
  setLinkMessage,
} from "../redux/snackbarSlice";

const mintingMessages = [
  "Calculating & Initializing gas fee payment...",
  "Minting a Rabbit. Please wait...",
  `Your rabbit is minted!`,
  "Something went wrong. Please try again.",
  "Your wallet address is not on the Whitelist, please contact us if you eligible to mint a rabbit!"
];
const abi = contract.abi;

export default function MintButton() {
  const [mintMessage, setMintMessage] = useState(0);
  const [minting, setMinting] = useState(false);
  const [progress, setProgress] = useState(false);
  const [mintButtonDisabled, setMintButtonDisabled] = useState(false);
  const [noWeb3, setNoWeb3] = useState(false);
  const dispatch = useDispatch();
  const reactWeb3 = useWeb3React();


  const handleCloseNoWeb3 = () => {
    setNoWeb3(false);
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#146483",
    fontSize: "80%",
    paddingTop: "4px",
    paddingBottom: "3px",
    paddingLeft: "8px",
    paddingRight: "9px",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#146483",
    },
    "@media screen and (max-width: 600px)": {
      fontSize: "60%",
    },
    "&:disabled": {
          backgroundColor: 'lightgrey'
        }
  }));

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        setMinting(true);
        setProgress(true);
        const checkIfInDb = await axios.get(firebaseNode + 'checkAddress?text=' + reactWeb3.account);
        if (checkIfInDb.data.result === true) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);

          setMintMessage(0);
          let nftTxn = await nftContract.mintRabbit(1, {
            value: ethers.utils.parseEther("0.00"),
          });

          setMintMessage(1);
          await nftTxn.wait();

          setProgress(false);
          setMinting(false);
          dispatch(setMessage(mintingMessages[2]));
          dispatch(setType("success"));
          dispatch(setLinkMessage("See Transaction"));
          dispatch(setLink("https://bscscan.com/tx/" + nftTxn.hash));
          dispatch(setOpen(true));
        } else {
          setProgress(false);
          setMinting(false);
          dispatch(setMessage(mintingMessages[4]));
          dispatch(setType("warning"));
          dispatch(setOpen(true));
        }
      } else {
        console.log("BSC object does not exist");
        setNoWeb3(true);
      }
    } catch (err) {
      setProgress(false);
      setMinting(false);
      dispatch(setMessage(mintingMessages[3]));
      dispatch(setType("warning"));
      dispatch(setOpen(true));
      console.log(err);
    }
  };

  useEffect(() => {
    let ownerContracts;
    async function fetchData() {
      if (reactWeb3.account) {
        try {
          let provider = new ethers.providers.JsonRpcProvider(moralisNode);
          const nftContract = new ethers.Contract(
            contractAddress,
            abi,
            provider
          );
          ownerContracts = await nftContract.tokensOfOwner(
            reactWeb3.account
          );
          if (ownerContracts.length > 0) {
            setMintButtonDisabled(true)
          } else {
            setMintButtonDisabled(false)
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchData();
  }, [reactWeb3.account]);

  return (
    <div>
      <StyledButton disabled={mintButtonDisabled} onClick={mintNftHandler}>MINT</StyledButton>
      <Dialog
        PaperProps={{
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        }}
        open={minting}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "200px",
            minHeight: "200px",
            padding: "50px",
          }}
        >
          {progress ? <CircularProgress sx={{ color: "lightgray" }} /> : null}
          <Typist
            className="TypingText"
            key={mintMessage}
            cursor={{
              show: false,
            }}
          >
            {mintingMessages[mintMessage]}
          </Typist>
        </Box>
      </Dialog>
      <Dialog
        open={noWeb3}
        onClose={handleCloseNoWeb3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Missing Metamask-wallet!"}
        </DialogTitle>
        <DialogContent>
          <p>
            You need to have Metamask installed in your browser to be able to
            connect.
          </p>
        </DialogContent>
        <DialogActions>
          <Link
            href="https://metamask.io"
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button sx={{ color: "#146483" }} onClick={handleCloseNoWeb3}>
              Visit Metamask
            </Button>
          </Link>
          <Button
            sx={{ color: "#146483" }}
            onClick={handleCloseNoWeb3}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
