import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  type: "default",
  link: "",
  linkMessage: "",
};

export const snackbarSplice = createSlice({
  name: "snackbar",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setLink: (state, action) => {
      state.link = action.payload;
    },
    setLinkMessage: (state, action) => {
      state.linkMessage = action.payload;
    },
  },
});

export const { setOpen, setMessage, setType, setLink, setLinkMessage } =
  snackbarSplice.actions;

export default snackbarSplice.reducer;
