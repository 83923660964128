import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { setOpen } from "../redux/snackbarSlice";
import { Link } from "@mui/material";

export default function SnackbarTemplate() {
  const snackbarStates = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setOpen(false));
  };

  const action = (
    <React.Fragment>
      {snackbarStates.link !== "" ? (
        <Link
          underline="none"
          href={snackbarStates.link}
          target="_blank"
          rel="noopener"
          className="LinkButtonInner"
        >
          {snackbarStates.linkMessage}
        </Link>
      ) : null}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={8000}
        onClose={handleClose}
        message={snackbarStates.message}
        action={action}
        severity={snackbarStates.type}
        sx={{ zIndex: 8000 }}
      />
    </div>
  );
}
