import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSpring, animated } from "react-spring";
import { useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import {
  setOwnerToken,
  setHighlightedToken,
} from "../components/redux/web3Slice";
import bottomFront from "../static/img/bottomFront.png";
import bottomBack from "../static/img/bottomBack.png";
import rabbitIcon from "../static/img/simpleLogoWhite.png";
import "../styles/App.css";

export default function Footer() {
  const reactWeb3 = useWeb3React();
  const location = useLocation();
  const stylesFront = useSpring({
    loop: { reverse: true },
    from: { x: "-3%" },
    to: { x: "-12%" },
    config: { duration: 10000 },
  });

  const stylesBack = useSpring({
    loop: { reverse: true },
    from: { x: "-12%" },
    to: { x: "-3%" },
    config: { duration: 10000 },
  });

  const { ownerToken } = useSelector((state) => state.web3);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setHighlightedToken(ownerToken));
  };

  useEffect(() => {
    if (!reactWeb3.active) {
      dispatch(setOwnerToken(false));
      dispatch(setHighlightedToken(false));
    }
  }, [reactWeb3.active, dispatch]);

  return (
    <div className="FooterInner">
      <animated.div
        style={{
          width: "120%",
          height: "20vh",
          gridColumn: 1,
          gridRow: 1,
          ...stylesBack,
        }}
      >
        <img src={bottomBack} className="BottomVawesBack" alt="Footer" />
      </animated.div>
      <animated.div
        style={{
          width: "120%",
          height: "20vh",
          gridColumn: 1,
          gridRow: 1,
          zIndex: 70,
          ...stylesFront,
        }}
      >
        <img src={bottomFront} className="BottomVawesFront" alt="Footer" />
      </animated.div>
      {ownerToken !== false && location.pathname === "/gallery" ? (
        <div className="RabbitIconDiv">
          <img
            onClick={handleClick}
            className="RabbitIcon"
            src={rabbitIcon}
            alt=""
          />
          <p>My Rabbit</p>
        </div>
      ) : null}
    </div>
  );
}
