import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connected: false,
  metamaskInfo: false,
  web3Wallet: false,
  ownerToken: false,
  highlightedToken: false,
};

export const web3Splice = createSlice({
  name: "web3",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setConnected: (state, action) => {
      state.connected = action.payload;
    },
    setMetamaskInfo: (state, action) => {
      state.metamaskInfo = action.payload;
    },
    setWeb3Wallet: (state, action) => {
      state.web3Wallet = action.payload;
    },
    setOwnerToken: (state, action) => {
      state.ownerToken = action.payload;
    },
    setHighlightedToken: (state, action) => {
      state.highlightedToken = action.payload;
    },
  },
});

export const {
  setConnected,
  setMetamaskInfo,
  setWeb3Wallet,
  setOwnerToken,
  setHighlightedToken,
} = web3Splice.actions;

export default web3Splice.reducer;
