import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { setMessage, setOpen, setType } from "./components/redux/snackbarSlice";
import { setWeb3Wallet } from "./components/redux/web3Slice";

import Header from "./views/Header";
import Start from "./views/Start";
import StartGallery from "./views/StartGallery";
import SnackbarTemplate from "./components/utils/Snackbar";
import Footer from "./views/Footer";
import "./styles/App.css";

function App() {
  const dispatch = useDispatch();
  const reactWeb3 = useWeb3React();

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      dispatch(
        setMessage(
          "No BSC browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
        )
      );
      dispatch(setType("warning"));
      dispatch(setOpen(true));
      dispatch(setWeb3Wallet(false));
      return;
    } else {
      console.log("Wallet exists! We're ready to go!");
      dispatch(setWeb3Wallet(true));
    }
  };

  function getLibrary(provider) {
    return new Web3(provider);
  }

  console.log(reactWeb3);

  useEffect(() => {
    checkWalletIsConnected();
  }, []);

  return (
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className="App">
          <Header />
          <div className="AppBody">
            <Routes>
              <Route path="/" element={<Start />} />
              <Route path="/gallery" element={<StartGallery />} />
            </Routes>
          </div>
          <Footer />
          <SnackbarTemplate />
        </div>
      </Web3ReactProvider>
    </BrowserRouter>
  );
}

export default App;

/*<Route path="/mint" element={<StartGallery mintPath={0} />}/>
<ButtonBase sx={{position: 'fixed', bottom: 0, right: 0, padding: '20px', paddingRight: '40px'}}>
            <Link underline="none" href="https://www.tiin.io" target="_blank" rel="noopener" className="LinkButtonInner">
              <p className="LinkButtonFirst" >visit</p>
              <p className="LinkButtonSecond" >TiiN.io</p>
            </Link>
          </ButtonBase>*/
