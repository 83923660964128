import React from "react";

import BodyFirst from "./start/BodyFirst";
import BodySecond from "./start/BodySecond";
import "../styles/App.css";

function Start() {
  return (
    <div className="AppBody">
      <BodyFirst />
      <BodySecond />
    </div>
  );
}

export default Start;
