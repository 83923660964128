import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import ButtonBase from "@mui/material/ButtonBase";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useNavigate } from "react-router-dom";

import { contractAddress, moralisNode, firebaseStorageImg, firebaseStorageMeta, ipfsGateway } from "../../config/config";
import contract from "../../contracts/TiiN_Rabbit_Collection.json";
import { getSingleRarity, getRaritys } from "../../components/web3/firebase";
import {
  setHighlightedToken,
  setOwnerToken,
} from "../../components/redux/web3Slice";
import LoadingRabbit from "../../components/utils/LoadingRabbit";
import NFTModal from "./NFTModal";
import loop256 from '../../static/img/loop256.gif'
import loadingRabbit from '../../static/img/loadingRabbit.GIF'
import PlaceholderImage from "../../components/elements/PlaceholderImage";
import "../../styles/App.css";

const abi = contract.abi;
const axios = require("axios");

let placeHolderArr = [];
for (let i = 1; i < 21; i++) {
  let tempImg = require("../../static/placeholders/Placeholder" + i + ".png");
  placeHolderArr.push(tempImg);
}

let contractToModal;

function PublicGallery() {
  const [mintedRabbits, setMintedRabbits] = useState([]);
  const [mintedRabbitsReverse, setMintedRabbitsReverse] = useState([]);
  const [selectedRabbit, setSelectedRabbit] = useState();
  const [nftSelected, setNFTSelected] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [columns, setColumns] = useState(3);
  const [placeholders, setPlaceholders] = useState(false);
  const [tokenID, setTokenID] = useState();
  const reactWeb3 = useWeb3React();
  const { ownerToken, highlightedToken } = useSelector((state) => state.web3);
  const [sorted, setSorted] = useState(true);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (!placeholders) {
      setPlaceholders(placeHolderArr);
    }
  }, [placeholders]);

  useEffect(() => {
    if (mintedRabbits.length < 1) {
      async function fetchData() {
        try {
          let provider = new ethers.providers.JsonRpcProvider(moralisNode);
          const nftContract = new ethers.Contract(
            contractAddress,
            abi,
            provider
          );
          contractToModal = nftContract;
          let nftTxn = await nftContract.allTokensWithMeta();
          let ownerContracts;

          setLoaded(true);
          let fireBaseCollection = await getRaritys(nftTxn.length - 1)
          let metadataArrReverse = [...fireBaseCollection];
          setMintedRabbits(fireBaseCollection)
          setMintedRabbitsReverse(metadataArrReverse.reverse());
          if (reactWeb3.account) {
                    ownerContracts = await nftContract.tokensOfOwner(
                      reactWeb3.account
                    );
                    if (ownerContracts.length > 0) {
                      let bigNumber = ethers.BigNumber.from(
                        ownerContracts[0]._hex
                      );
                      let tempId = bigNumber.toNumber();
                      dispatch(setOwnerToken(tempId));
                    }
                  }
          
        } catch (e) {
          console.error(e);
        }
      }
      fetchData();
    }
  }, [mintedRabbits, reactWeb3.account, dispatch]);

  useEffect(() => {
    let ownerContracts;
    async function fetchData() {
      if (reactWeb3.account && mintedRabbits.length > 0) {
        try {
          ownerContracts = await contractToModal.tokensOfOwner(
            reactWeb3.account
          );
          if (ownerContracts.length > 0) {
            let bigNumber = ethers.BigNumber.from(ownerContracts[0]._hex);
            let tempId = bigNumber.toNumber();
            dispatch(setOwnerToken(tempId));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchData();
  }, [ownerToken, dispatch, reactWeb3.account, mintedRabbits.length]);

  useEffect(() => {
    checkColumnAmount();
  }, []);

  const checkColumnAmount = () => {
    let width = window.innerWidth;
    if (width < 600) {
      return setColumns(2);
    }
    if (width > 600 && width < 900) {
      return setColumns(3);
    }
    if (width > 900) {
      return setColumns(4);
    }
  };

  const handleOpenModal = (index) => {
    setTokenID(index);
    setSelectedRabbit(mintedRabbits[index]);
    setNFTSelected(true);
  };

  const handleCloseModal = () => {
    setNFTSelected(false);
    setSelectedRabbit();
    dispatch(setHighlightedToken(false));
  };

  const handleSortClick = () => {
    setSorted(!sorted);
  };

  useEffect(() => {
    if (highlightedToken !== false) {
      setTokenID(highlightedToken);
      setSelectedRabbit(mintedRabbits[highlightedToken]);
      setNFTSelected(true);
    }
  }, [highlightedToken]);

  return (
    <div className="Gallery">
      {mintedRabbits.length > 0 ? (
        <div className="GalleryInner">
          {mintedRabbitsReverse.length === mintedRabbits.length ? (
            <IconButton
            onClick={() => handleSortClick()}
            size="sm"
            sx={{
              position: "absolute",
              top: "1%",
              left: { xs: 0, lg: -30 },
              color: "#146483",
              zIndex: 100,
            }}
          >
            <ImportExportIcon />
          </IconButton>
          ):(
            null
          )}
          <ImageList
            gap={2}
            cols={columns}
            sx={{
              maxWidth: "84vw",
              height: "100%",
              paddingTop: 0,
              marginTop: 0,
              paddingBottom: "200px",
            }}
          >
            {sorted
              ? mintedRabbits.map((item, index) => (
                  <ButtonBase
                    sx={{
                      margin: "10%",
                    }}
                    key={index}
                    onClick={() => handleOpenModal(index)}
                  >
                    <ImageListItem
                      sx={{
                        boxShadow: "0 1px 3px 0px #808080",
                        borderRadius: "8px",
                        border: "3px solid #f7f7f7",
                        marginTop: "16px",
                        maxWidth: "34vw",
                        minWidth: '18%'
                      }}
                    >
                      <PlaceholderImage 
                        imageClass={'ListImage'} 
                        placeholderClass={'ListImageHide'} 
                        mainImage={`${item.off_chain_thumbnail}&w=248&fit=crop&auto=format`}
                        placeholderImage={`${loop256}?w=248&fit=crop&auto=format`}
                        />
                      <ImageListItemBar
                        subtitle={item.name}
                        position="below"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          backgroundColor: "#f7f7f7",
                          borderRadius: "0 0 4px 4px",
                        }}
                      />
                    </ImageListItem>
                  </ButtonBase>
                ))
              : mintedRabbitsReverse.map((item, index) => (
                  <ButtonBase
                    key={"reverse" + index}
                    onClick={() =>
                      handleOpenModal(mintedRabbitsReverse.length - index - 1)
                    }
                    sx={{
                      margin: "10%",
                    }}
                  >
                    <ImageListItem
                      sx={{
                        boxShadow: "0 1px 3px 0px #808080",
                        borderRadius: "8px",
                        border: "3px solid #f7f7f7",
                        marginTop: "16px",
                        maxWidth: "34vw",
                        minWidth: '18%'
                      }}
                    >
                      <PlaceholderImage 
                        imageClass={'ListImage'} 
                        placeholderClass={'ListImageHide'} 
                        mainImage={`${item.off_chain_thumbnail}&w=248&fit=crop&auto=format`}
                        placeholderImage={`${loop256}?w=248&fit=crop&auto=format`}
                        />
                      <ImageListItemBar
                        subtitle={item.name}
                        position="below"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          backgroundColor: "#f7f7f7",
                          borderRadius: "0 0 4px 4px",
                        }}
                      />
                    </ImageListItem>
                  </ButtonBase>
                ))}

            {mintedRabbits.length < 30 && mintedRabbitsReverse.length === mintedRabbits.length
              ? placeholders
                ? placeholders.map((item, index) => (
                    <ButtonBase
                      key={index}
                      onClick={() => navigate(`/`)}
                      sx={{
                        margin: "10%",
                      }}
                    >
                      <ImageListItem
                        sx={{
                          boxShadow: "0 1px 3px 0px #808080",
                          borderRadius: "8px",
                          border: "3px solid #f7f7f7",
                          marginTop: "16px",
                          maxWidth: "34vw",
                          minWidth: '18%'
                        }}
                      >
                        <img
                          src={item.default}
                          srcSet={item.default}
                          alt={""}
                          loading="lazy"
                          className="ListImage"
                        />
                        <ImageListItemBar
                          subtitle={"Click to mint a rabbit!"}
                          position="below"
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            paddingLeft: "5px",
                            paddingTop: "5px",
                            backgroundColor: "#f7f7f7",
                            borderRadius: "0 0 4px 4px",
                            opacity: 1,
                          }}
                        />
                      </ImageListItem>
                    </ButtonBase>
                  ))
                : null
              : null}
          </ImageList>
        </div>
      ) : (
        <LoadingRabbit textLabel="Chasing rabbits on Binance Smart Chain. This might take a minute depending on blockchain traffic, please do not refresh or close the tab." />
      )}
      <NFTModal
        selfMetadata={selectedRabbit}
        allMetadata={mintedRabbits}
        openModal={nftSelected}
        handleCloseModal={handleCloseModal}
        contract={contractToModal}
        tokenID={tokenID}
      />
    </div>
  );
}

export default PublicGallery;
