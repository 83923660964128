import React from "react";

import loadingRabbit from "../../static/img/loadingRabbit.GIF";

function LoadingRabbit(props) {
  return (
    <div className="LoadingRabbit">
      <img width="350px" src={loadingRabbit} alt="" />
      <p className="LoadingRabbitLabel">{props.textLabel}</p>
    </div>
  );
}

export default LoadingRabbit;
