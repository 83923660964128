import React from "react";
import PublicGallery from "./gallery/PublicGallery";
import "../styles/App.css";

function StartGallery() {
  return (
    <div className="StartGallery">
      <PublicGallery />
    </div>
  );
}

export default StartGallery;
