import React from "react";
import { useSelector, useDispatch } from "react-redux";

import "../../styles/App.css";

export default function BodyFirst() {
  return (
    <div className="AppBodyFirst">
      <div className="AppBodyFirstInner">
        <p className="AppBodyFirstTitle">Get your very own TiiN Rabbit</p>
        <p className="AppBodyFirstText">
          Our certified expert in blockchain navigation, helping you to get the most out of your digital assets on TiiN.
        </p>
        <p className="AppBodyFirstText">
          Your NFT rabbit is a membership pass to our VIP discord channel where you can get access to alpha news, exclusive talks, and claim token airdrops. 
        </p>
        <p className="AppBodyFirstText">
          Hippity Hop!
        </p>
      </div>
    </div>
  );
}
