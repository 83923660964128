import React, { useState, useEffect } from "react";

import "../../styles/App.css";

export default function PlaceholderImage(props) {
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true)
  }

  return (
      <>
      <img
        alt="main-img"
        src={props.mainImage}
        onLoad={onLoad}
        className={loaded ? props.imageClass : props.placeholderClass}

      />
      <img
        alt="placeholder"
        src={props.placeholderImage}
        className={loaded ? props.placeholderClass : props.imageClass}
      />
    </>
  );
}
