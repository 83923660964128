import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import wordmarkWhite from "../static/img/wordmarkWhite.png";
import ConnectButtonHeader from "../components/elements/ConnectButtonHeader";
import "../styles/App.css";

export default function Header() {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="AppHeader">
      <div className="AppBar">
        <div className="ToolBar">
          <img
            className="Wordmark"
            onClick={() => navigate(`/`)}
            src={wordmarkWhite}
            alt=""
          />
          <div className="ToolBarInner">
            <Box sx={{ display: { xs: "flex", sm: "none" } }}>
              <IconButton
                onClick={handleClick}
                size="sm"
                sx={{ color: "#fff", alignSelf: "flex-end" }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Link
                    underline="hover"
                    href="https://tiin.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#000" }}
                  >
                    TiiN.io
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    onClick={() => navigate(`/gallery`)}
                    underline="hover"
                    sx={{ color: "#000" }}
                  >
                    Gallery
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    underline="hover"
                    href="https://discord.gg/k3QAh9uvM2"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#000" }}
                  >
                    VIP Discord
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <Link
                underline="hover"
                href="https://tiin.io"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#fff" }}
              >
                <Button
                  size="small"
                  variant="text"
                  sx={{
                    color: "#fff",
                    textTransform: "none",
                    paddingTop: "8px",
                  }}
                >
                  TiiN.io
                </Button>
              </Link>
              <Link underline="hover" sx={{ color: "#fff" }}>
                <Button
                  size="small"
                  onClick={() => navigate(`/gallery`)}
                  variant="text"
                  sx={{ color: "#fff", paddingTop: "8px" }}
                >
                  Gallery
                </Button>
              </Link>
              <Link
                underline="hover"
                href="https://discord.gg/k3QAh9uvM2"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#fff" }}
              >
                <Button
                  size="small"
                  variant="text"
                  sx={{ color: "#fff", marginLeft: "8px", paddingTop: "8px" }}
                >
                  VIP Discord
                </Button>
              </Link>
            </Box>
            <ConnectButtonHeader />
          </div>
        </div>
      </div>
    </div>
  );
}
