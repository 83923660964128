import React, { useState, useEffect, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import { useSpring, animated } from "react-spring";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import Link from "@mui/material/Link";

import useWindowDimensions from "../../components/utils/WindowResize";
import "../../styles/App.css";

import ConnectButton from "../../components/elements/ConnectButton";
import MintButton from "../../components/elements/MintButton";

import card1 from "../../static/cards/webCard1-sm.jpg";
import card2 from "../../static/cards/webCard2-sm.jpg";
import card3 from "../../static/cards/webCard3-sm.jpg";
import card4 from "../../static/cards/webCard4-sm.jpg";
import whiteLogo from "../../static/img/simpleLogoWhite.png";
import coloredLogo from "../../static/img/simpleLogo.png";

export default function BodySecond() {
  const { height, width } = useWindowDimensions();
  const [flipped, set] = useState(false);
  const [mintMessage, setMintMessage] = useState("");
  const [minting, setMinting] = useState(false);
  const [progress, setProgress] = useState(false);
  const [txLink, setTxLink] = useState("");
  const { active } = useWeb3React();
  const [elementWidth, setElementWidth] = useState(0);
  const elementRef = useRef(null);

  const handleClose = () => {
    setMinting(false);
    setProgress(false);
    setMintMessage(``);
    setTxLink("");
  };

  useEffect(() => {
    setElementWidth(elementRef.current.getBoundingClientRect().width);
  }, [width, height]); //empty dependency array so it only runs once at render

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `rotateY(${flipped ? -180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80, duration: 300 },
  });

  const styles1 = useSpring({
    from: { x: width },
    to: { x: 0 },
    config: { duration: 1000 },
  });

  const styles2 = useSpring({
    delay: 300,
    from: { x: width },
    to: { x: 0 },
    config: { duration: 1000 },
  });

  const styles3 = useSpring({
    delay: 600,
    from: { x: width },
    to: { x: 0 },
    config: { duration: 1000 },
  });

  const styles4 = useSpring({
    delay: 900,
    from: { x: width },
    to: { x: 0 },
    config: { duration: 1000 },
  });
  const styles5 = useSpring({
    delay: 1500,
    from: { x: width, scale: 0.8 },
    to: [{ x: 0 }, { scale: 1 }],
    config: { duration: 800 },
  });

  setTimeout(() => {
    set(true);
  }, 2800);

  return (
    <div className="AppBodySecond">
      <div className="AppBodySecondInner">
        <animated.div
          style={{
            height: "60%",
            position: "absolute",
            maxHeight: "800px",
            minHeight: "130px",
            left: width < 600 ? ((width - (elementWidth * 1.4))/2) + ((width/8) * 4) : '72%',
            aspectRatio: "1 / 1.1",
            boxShadow: "0 1px 3px 0px #808080",
            borderRadius: "8px",
            backgroundColor: "#fff",
            ...styles1,
          }}
        >
          <img src={card4} className="NFTCards" alt="" />
        </animated.div>
        <animated.div
          style={{
            height: "60%",
            position: "absolute",
            maxHeight: "800px",
            minHeight: "130px",
            left: width < 600 ? ((width - (elementWidth * 1.4))/2) + ((width/8) * 3) : '57%',
            aspectRatio: "1 / 1.1",
            boxShadow: "0 1px 3px 0px #808080",
            borderRadius: "8px",
            backgroundColor: "#fff",
            ...styles2,
          }}
        >
          <img src={card3} className="NFTCards" alt="" />
        </animated.div>
        <animated.div
          style={{
            height: "60%",
            position: "absolute",
            maxHeight: "800px",
            minHeight: "130px",
            left: width < 600 ? ((width - (elementWidth * 1.4))/2) + ((width/8) * 2) : '42%',
            aspectRatio: "1 / 1.1",
            boxShadow: "0 1px 3px 0px #808080",
            borderRadius: "8px",
            backgroundColor: "#fff",
            ...styles3,
          }}
        >
          <img src={card2} className="NFTCards" alt="" />
        </animated.div>
        <animated.div
          style={{
            height: "60%",
            position: "absolute",
            maxHeight: "800px",
            minHeight: "130px",
            left: width < 600 ? ((width - (elementWidth * 1.4))/2) + (width/8) : '27%',
            aspectRatio: "1 / 1.1",
            boxShadow: "0 1px 3px 0px #808080",
            borderRadius: "8px",
            backgroundColor: "#fff",
            ...styles4,
          }}
        >
          <img src={card1} className="NFTCards" alt="" />
        </animated.div>
        <animated.div
          ref={elementRef}
          style={{
            height: "75%",
            position: "absolute",
            maxHeight: "900px",
            minHeight: "150px",
            left: width < 600 ? (width - (elementWidth * 1.55))/2 : '12%',
            aspectRatio: "1 / 1.4",
            borderRadius: "8px",
            minWidth: '120px',
            ...styles5,
          }}
        >
          <div className="NFTCardBodyMain">
            <animated.div
              className={`SpinningCard`}
              style={{
                opacity: opacity.to((o) => 1 - o),
                transform,
                backgroundColor: "#fff",
                boxShadow: "0 1px 3px 0px #808080",
                borderRadius: "8px 8px 8px 8px",
                width: '100%',
              }}
            >
              <div className={`SpinningCardBack`}>
                <img className="CardLogo" src={whiteLogo} alt="" />
              </div>
            </animated.div>
            <animated.div
            ref={elementRef}
              className={`SpinningCard`}
              style={{
                opacity,
                backgroundColor: "#fff",
                width: '100%',
                transform,
                rotateY: "-180deg",
                borderRadius: "8px 8px 8px 8px",
                boxShadow: "0 1px 3px 0px #808080",
              }}
            >
              <div className={`SpinningCardFront`}>
                <img className="CardLogo" src={coloredLogo} alt="" />
                <div className={`SpinningCardFrontInner`}>
                  <p className="CardTitle">Adopt a rabbit</p>
                  {active ? <MintButton /> : <ConnectButton />}
                </div>
              </div>
            </animated.div>
          </div>
        </animated.div>
      </div>
      <Dialog onClose={handleClose} open={minting}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "200px",
            minHeight: "200px",
            padding: "50px",
          }}
        >
          {progress ? <CircularProgress /> : null}
          <p>{mintMessage}</p>
          {txLink !== "" ? (
            <Link
              underline="none"
              href={txLink}
              target="_blank"
              rel="noopener"
              className="LinkButtonInner"
            >
              See Transaction
            </Link>
          ) : null}
        </Box>
      </Dialog>
    </div>
  );
}
